import { React, useEffect, useState } from "react";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsName,
    useWaitForTransaction,
    usePrepareSendTransaction,
    useSendTransaction,
    useContractRead,
    useNetwork,
    useSwitchNetwork,
    useBalance
} from "wagmi";
import abi from '../contract/abi.json';

import { useDebounce } from 'use-debounce'
import { utils, ethers } from 'ethers'
import FAQ from "./FAQ";

function judgeAddress(result, amount, price){
    if (result.data !== undefined){
        let balance = parseFloat(result.data.formatted)
        if (balance >= amount * price){
            return true
        }
    }
    return false
}


function Body() {
    const { chain } = useNetwork()
    const { switchNetwork } = useSwitchNetwork()
    let network = 1


        

    const { address, isConnected } = useAccount();
    const { connect, connectors } = useConnect();
    const { disconnect } = useDisconnect();

    const { data: totalSupplyData } = useContractRead({
        address: process.env.REACT_APP_CONTRACT_ADDRESS,
        abi: abi,
        functionName: 'totalSupply',
        watch: true,
    })


    const [totalMinted, setTotalMinted] = useState(0);

    useEffect(() => {
        if (totalSupplyData) {
            setTotalMinted(totalSupplyData.toNumber())
        }
    }, [totalSupplyData])

    const { data: ensName } = useEnsName({ address });
    const [amount, setAmount] = useState('1')
    let public_price = 0.08

    const [debouncedValue] = useDebounce(parseInt(amount) * public_price, 500)
    const { config } = usePrepareSendTransaction({
        request: {
            to: process.env.REACT_APP_CONTRACT_ADDRESS,
            value: debouncedValue ? utils.parseEther(debouncedValue.toString()) : undefined,
            data: "0xce6df2b9" + address?.slice(2,).padStart(64, '0') + amount.padStart(64, '0')
        },
    })

    const { data, sendTransaction } = useSendTransaction(config)

    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

    // WL Mint
    const [proof, setProof] = useState([]);
    const [whitelist, setWhiteList] = useState(false);
    let wl_price = 0.08
    let abiCoder = new ethers.utils.AbiCoder();

    const [debouncedValueWL] = useDebounce(parseInt(amount) * wl_price, 500)
    const { config: configWL } = usePrepareSendTransaction({
        request: {
            to: process.env.REACT_APP_CONTRACT_ADDRESS,
            value: debouncedValueWL ? utils.parseEther(debouncedValueWL.toString()) : undefined,
            data: "0x81e1ced3" + abiCoder.encode([ "uint64", "bytes32[]" ], [amount ? amount : 1, proof.length===0 ? ['0xedd72d2b16898d380a414c3c65873d4b0c2754143e5cfe4aa2ac0114a57ae32a'] : proof]).slice(2,)
        },
    })
    const {  data: dataWL,  sendTransaction : sendTransactionWL } = useSendTransaction(configWL)

    const { isLoading:isLoadingWL, isSuccess:isSuccessWL } = useWaitForTransaction({
        hash: dataWL?.hash,
    })

    useEffect(() => {
        const fetchData = async () => {
            let proof = await (await fetch(`https://greenearthproof.vercel.app/api?address=${address}`)).json()
            if (proof.length > 0) {
                proof = proof[0].proof
                setProof(proof)
                setWhiteList(true)
            } else {
                setProof([])
                setWhiteList(false)
            }
        }
        fetchData()
    }, [address])

    const result = useBalance({address: address})










    return (
        <>
            <div className="header">
                <div className="header-top">
                    <div className="container clearfix">
                        <div className="pull-left logo"><a href="/"><img src="/images/logo.png" alt="Logo Png" /></a></div>
                        <div className="pull-left header-box">
                            <ul className="pull-left nav-list clearfix">
                                <li><a href="#page-one">home</a></li>
                                <li><a href="#page-one">about us</a></li>
                                <li><a href="#page-three">Our Team</a></li>
                            </ul>
                            <div className="pull-left header-a">
                                <a href="https://twitter.com/GreenEarthDAO" target="_blank" rel="noreferrer"><img src="/images/bird.png" alt="Twitter Png" /></a>
                                <a href="https://discord.gg/hwgEWpQN4m" target="_blank" rel="noreferrer"><img src="/images/pic.png" alt="Discord Png" /></a>
                                   <a href="https://opensea.io/collection/greenearthdao-v2" target="_blank" rel="noreferrer"><img src="/images/Frame.png" alt="Opensea Png" /></a>
                            <a href="https://looksrare.org/collections/0xd963EEB989716e4Af761bE0D11F4E11CbD3D5445?queryID=a4ff42ddb7aca03035529d11ecfef999" target="_blank" rel="noreferrer"><img src="/images/Subtract.png" alt="Lookrare Png" /></a>
                             </div>
                        </div>
                        <div>
                            {isConnected ?
                                (chain.id === network ? <button className="btn-link pull-right" onClick={disconnect}> {ensName ? ensName : address.slice(0, 4) + '...' + address.slice(38, 42)}</button>
                                    :
                                    <button className="btn-link pull-right" style={{ backgroundColor: 'red' }} onClick={() => switchNetwork(network)}> Change Network</button>)
                                :
                                connectors.map((connector) => (
                                    <button
                                        className="btn-link pull-right"
                                        disabled={!connector.ready}
                                        key={connector.id}
                                        onClick={() => connect({ connector })}
                                    >
                                        Connect Wallet
                                    </button>
                                ))}
                        </div>
                    </div>
                    <div className="menu-button">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="banner">
                    <div className="container clearfix">
                        <div className="pull-left banner-left">
                            <div className="banner-p">
                            MINT后空投代币到钱包 Airdrop tokens to wallet after MINT
                            <br />
                           BSC链添加代币的合约：0x8d957b84380De62dbCc12037EeA33C42a254b5b7 

                            </div>
                            <div className="banner-back">
                                <div className="banner-back-tt text-center">NFT Minted: {totalMinted}/1440</div>
                                <div className="banner-back-p text-center">价格: 0.08 ETH，每个钱包最多mint5个<br /><br />
                                    Public mint 0.08 ETH  &nbsp;Up to 5 per Wallet<br />
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div onClick={()=>{
                                            if (parseInt(amount) > 1){
                                                setAmount((parseInt(amount) - 1).toString())
                                            }
                                        }} className="input-group-addon input-move">-</div>
                                        <input onChange={(e) => setAmount(e.target.value)} className="form-control" value={amount} />   
                                        <div onClick={()=>{
                                            if(parseInt(amount) < 5){
                                                setAmount((parseInt(amount) + 1).toString())
                                            }
                                        } } className="input-group-addon input-add">+</div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            sendTransaction?.()
                                        }}
                                    >
                                        <button disabled={isLoading || !sendTransaction || amount > 5 || !judgeAddress(result, amount, public_price)} className="btn btn-banner"> {isLoading ? 'Minting ...' : (judgeAddress(result, amount, public_price) ? 'Public Mint' : "Balance wrong" )} </button>
                                        {isSuccess && (
                                            <div style={{ backgroundColor: '#F8FF33', borderRadius: '30px', marginTop: '6px' }} >
                                                Successfully mint {amount} nft.
                                                <div>
                                                    Check it 👉 <a href={`https://etherscan.io/tx/${data?.hash}`} target="_blank" rel="noreferrer">Etherscan 👈</a>
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                    <style> </style>

                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            sendTransactionWL?.()
                                        }}
                                    >
                                        <button disabled={isLoadingWL || !sendTransactionWL || amount > 5 || !judgeAddress(result, amount, wl_price)} className="btn btn-banner"> {whitelist ? (isLoadingWL ? "Minting ..." : (judgeAddress(result, amount, wl_price) ? 'WhiteList Mint' : "Balance wrong")  ) : 'Your are not wl'} </button>
                                        {isSuccessWL && (
                                            <div style={{ backgroundColor: '#F8FF33', borderRadius: '30px', marginTop: '6px' }} >
                                                Successfully mint {amount} nft.
                                                <div>
                                                    Check it 👉 <a href={`https://etherscan.io/tx/${dataWL?.hash}`} target="_blank" rel="noreferrer">Etherscan 👈</a>
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="banner-img pull-right"><img src="images/banner.png" alt="Banner PNG" /></div>
                    </div>
                </div>
            </div>

            <div id="page-one" className="page-one">
                <div className="container clearfix">
                    <div className="pull-left img-one">
                        <div className="carousel-inner" data-ride="carousel" data-interval="2000">
                            <div className="item active">
                                <img src="/images/ppt/1.png" alt="PPT 1PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/2.png" alt="PPT 2PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/3.png" alt="PPT 3PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/4.png" alt="PPT 4PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/5.png" alt="PPT 5PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/6.png" alt="PPT 6PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/7.png" alt="PPT 7PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/8.png" alt="PPT 8PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/9.png" alt="PPT 9PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/10.png" alt="PPT 10PNG" />
                            </div>
                            <div className="item">
                                <img src="/images/ppt/11.png" alt="PPT 11PNG" />
                            </div>
                        </div>
                    </div>
                    <div className="pull-left one-right">
                        <div className="one-p">
                            <p><b>什么是GREENEARTHDAO NFT？</b><br /><br />
                                中文简称：地球DAO，是发行在以太坊区块链的1440个NFT，为动植物拍照打卡，可以边拍边赚(快照以后获得糖果)，建造第一个保护地球的虚实结合元宇宙，是2022年ETHDenver
                                黑客松入选项目。</p>
                            <p><br />GREENEARTHDAO NFT(Photo to Earn), you can take photos of animals and plants in a
                                Photo-to-Earn business model(Get airdrop after snapshot), to build the first virtual reality
                                combination meta universe project with the mission of protecting the earth.It be selected in
                                2022 ETHDenber.</p>
                        </div>

                    </div>
                </div>
            </div>



            <div className="page-tow">
                <div className="container">
                    <div className="tow-title text-center">The philosophy of <span>GREENEARTHDAO</span> as follows</div>
                    <ul className="tow-ul">
                        <li>
                            <div className="ul-tt"> .</div>
                            <div className="tow-ul-p">拥有地球DAO
                                NFT，你可以用APP+云储存，拍摄动植物照片，将时间，地理位置，照片数字ID记录在区块链上。为科学研究提供时间+经纬度+天气温度的数据，保护地球，吸引更多web2用户入场，用web3探索物种多样性，作为申请诺贝尔和平奖的一份子。<br />
                                With GREENEARTHDAO NFT, you can use our APP+cloud storage to take photos of animals and plants
                                and record the time, geographical location and photo digital ID on the blockchain. This unique
                                time, geographic and temperature data would be stored on the blockchain for further scientific
                                research to protect the earth, explore species diversity, and discover new species.You can be a
                                part of apply for the The Nobel peace Prize..</div>
                        </li>
                        <li>
                            <div className="ul-tt">.</div>
                            <div className="tow-ul-p">拥有地球DAO NFT，你的拍照NFT可以参加野生动物手机摄影大赛，猫猫摄影大赛，狗狗摄影大赛。<br />
                                With GREENEARTHDAO NFT, your photo NFTs can participate in various Wildlife Mobile Photography
                                Contest, Cat Photography Contests and Dog Photography Contests, with unique identifiers ordinary
                                pictures do not haven.</div>
                        </li>
                        <li>
                            <div className="ul-tt">.</div>
                            <div className="tow-ul-p">这个项目从中国出发，覆盖全世界，因为中国拥有脊椎动物6445种，占世界总种的13.7%；高等植物34984种，居世界第三位。<br />
                                This project was started in China but intends to cover the entire world. China has over 6,000
                                vertebrates, accounting for 13.7% of the world's total species; There are also around 35,000
                                species of plants, ranking third in the world. In short, China is a center of gravity for
                                biodiversity and will provide a great foundation for this project to grow quickly to other parts
                                of the world.</div>
                        </li>
                        <div className="tow-title text-center"> <span>Roadmap</span> </div>
                        <ul className="tow-ul">
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2022年12月&nbsp;&nbsp;&nbsp;NFT上市<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    NFT will be launched</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2022年12月&nbsp;&nbsp;&nbsp;手机APP版本升级（Photo to Earn）<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Research and develop mobile phone APP (Photo to Earn).</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">
                                   2023年1月&nbsp;&nbsp;&nbsp;  10名🌾NFT持有者去泰国拍水稻+BLACKPINK1月8日曼谷演唱会（承包吃住门票），名额先到先得，见Discord<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    NFT 10 holders will go to Thailand to take pictures of the rice+BLACKPINK Bangkok concert
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    (contract for food and accommodation tickets)</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年4月&nbsp;&nbsp;&nbsp;手机APP测试+上市<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Mobile APP test+launch</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年4月&nbsp;&nbsp;&nbsp; 10名🌸 NFT持有者去日本拍樱花（承包吃住门票），名额先到先得，见Discord<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    10 NFT holders will go to Japan to take pictures of sakura <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    (contract for food and accommodation tickets).</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年5月&nbsp;&nbsp;&nbsp;Photo to Earn上市<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Photo to Earn will be listed.
                                </div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年6月&nbsp;&nbsp;&nbsp;10名NFT持有者去海南国家公园拍桫椤（承包吃住门票），名额先到先得，见Discord<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    10 NFT holders will go to Hainan National Park of china to take photos of alsophila
                                    spinulosa
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    (contract for food and accommodation tickets)
                                </div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年7月&nbsp;&nbsp;&nbsp;2023年度野生动物手机摄影大赛上市<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    The 2023 Wildlife Mobile Photography Competition will be launched</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年8月&nbsp;&nbsp;&nbsp;2023年度猫猫🐱摄影大赛上市，2023年度狗狗🐶摄影大赛上市<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    The Cat Photography Competition will be launched,The Dog photography competition will be
                                    launched in 2023</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年9月&nbsp;&nbsp;&nbsp;抽奖10名NFT持有者去日本拍猫头鹰+参加2023年东京游戏展（承包吃住门票）<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    10 NFT holders will go to Japan to shoot owls+participate in the 2023 Tokyo Game Show
                                    <br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    (contract for food and accommodation tickets)
                                </div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2023年10月&nbsp;&nbsp;&nbsp;参与2024年度诺贝尔和平奖提名<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Participate in the nomination of the Nobel Peace Prize for 2024</div>
                            </li>
                            <li>
                                <div className="ul-tt">.</div>
                                <div className="tow-ul-p">2024年1月&nbsp;&nbsp;&nbsp;为2023年度摄影大赛颁奖<br />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    Award prizes for the 2023 annual photography competition</div>
                            </li>
                        </ul>
                    </ul>
                </div>
            </div>


            <div id="page-three" className="page-three">
                <div className="container">
                    <div className="three-title text-center">OUR TEAM</div>
                    <div className="text-center"><img src="images/lind02.png" alt="lind02 PNG" /></div>
                    <ul className="team-list clearfix">
                        <li className="clearfix">
                            <div className="pull-left team-img"><img src="images/05.png" alt="lind02 PNG" /></div>
                            <div className="team-list-right">
                                <div className="team-list-tt">TT</div>
                                <div className="team-list-a"><a href="https://twitter.com/GreenEarthDAO">灵魂Soul-Founder</a></div>
                                <div className="team-list-p">多年游戏APP全球运营经验<br />熟悉中日韩美user画像<br />喜欢代码+社区+动植物 <br />不喜欢买游艇和别墅</div>

                            </div>
                        </li>
                        <li className="clearfix">
                            <div className="pull-left team-img"><img src="images/06.png" alt="lind02 PNG" /></div>
                            <div className="team-list-right">
                                <div className="team-list-tt">ROBOTDAO </div>
                                <div className="team-list-a"><a href="https://twitter.com/cryptochasersco">社区Community</a></div>
                                <div className="team-list-p">深度耕耘Web2+Web3<br />脑力执行力全球领先的科学家<br />喜欢撸猫，也喜欢黑丝<br />奇奇怪怪，可可爱爱</div>

                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <FAQ/>

            <div className="footer">
                <div className="container text-center">
                    <a href="https://twitter.com/GreenEarthDAO" target="_blank" rel="noreferrer"><img src="images/f03.png" alt="F03 Png" /></a>
                    <a href="https://discord.gg/hwgEWpQN4m" target="_blank" rel="noreferrer"><img src="images/f04.png" alt="F03 Png" /></a>
                        <a href="https://opensea.io/collection/greenearthdao-v2" target="_blank" rel="noreferrer"><img src="/images/Frame.png" alt="Opensea Png" /></a>
                    <a href="https://looksrare.org/collections/0xd963EEB989716e4Af761bE0D11F4E11CbD3D5445?queryID=a4ff42ddb7aca03035529d11ecfef999" target="_blank" rel="noreferrer"><img src="images/f01.png" alt="F03 Png" /></a>
                </div>
            </div>







        </>
    )

}

export default Body
