import { useState } from "react";

function FAQ() {

    const [sethree,setSecThree] = useState(false);
    const [seone,setSecOne] = useState(false);
    const [setwo,setSecTwo] = useState(false);

    return (
        <div className="page-four">
            <div className="container">
                <div className="faq-title text-center">FAQ</div>
                <ul className="faq-ul">
                    <li >
                        {/* <div className="faq-ul-tt">什么时候mint？When start mint?</div> */}
                        <button onClick={()=>setSecOne(!seone)} className="faq-ul-tt" style={{background: '#FFEF5E', border:'none'}} >什么时候mint？When start mint?</button>
                        <div className="faq-bottom" style={seone===false ? {} : { display: "block" }}>
                            时间：2022年12月12日周一晚8点（新加坡时区）<br />
                            MINT后进入Discord_Vip room，一起探索世界<br />
                            Mint time is December 12, 2022 at 8pm in Singapore time zone.<br />
                            Enter Discord_Vip room after MINT NFT, explore the world together<br />
                        </div>
                    </li>
  <li >
                        <button onClick={()=>setSecThree(!sethree)} className="faq-ul-tt" style={{background: '#FFEF5E', border:'none'}} >持有NFT怎么空投token？How to get airdrop with hold NFT?</button>
                        <div className="faq-bottom" style={sethree===false ? {} : { display: "block" }}>
                            <div style={{ textAlign: "left" }}> Mint NFT后，根据持有时间，地址自动收到token，边玩边赚token预计2023年交易
                            </div>
                            BSC链添加代币的合约：0x8d957b84380De62dbCc12037EeA33C42a254b5b7
                             <br />
                            <div style={{ textAlign: "left" }} >Hold NFT to
                                become the first batch of users, and get airdrop according to the snapshot time.<br />
                                 The contract address of the bsc chain<br /> 
                            0x8d957b84380De62dbCc12037EeA33C42a254b5b7
                             </div>
                            <div style={{ textAlign: "left" }}><br /></div>

                        </div>
                    </li>
                    <li >
                        {/* <div className="faq-ul-tt">持有NFT怎么抽奖？How to draw a lottery with hold NFT?</div> */}
                        <button onClick={()=>setSecTwo(!setwo)} className="faq-ul-tt" style={{background: '#FFEF5E', border:'none'}} >持有NFT怎么抽奖？How to draw a lottery with hold NFT?</button>
                        <div className="faq-bottom" style={setwo===false ? {} : { display: "block" }}>
                            
                            2023年在水稻NFT用户之中选10名去泰国，机会先到先得<br />
                            NFT 10 holders will be drawn from rice nfts to Thailand in 2023.<br />
                            2023年在樱花NFT用户之中选10名去日本，机会先到先得<br />
                            NFT 10 holders will be drawn from sakura nfts to Japan in 2023.<br />
                            2023年在桫椤NFT用户之中抽奖10名去中国海南国家森林公园<br />
                            NFT 10 holders will be drawn from alsophila spinulosa nfts to Japan in 2023.<br />
                            。。。。。<br />
                            以此类推，每一个NFT都有对应的抽奖活动。
                            And so on,Each nft has a corresponding lottery.

                        </div>
                    </li>
                
                </ul>
            </div>
        </div>
    )
}

export default FAQ
