import Body from './components/Body';
import { WagmiConfig, createClient, configureChains,defaultChains } from 'wagmi'
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { MetaMaskConnector } from "wagmi/connectors/metaMask"

const alchemyId = process.env.REACT_APP_ALCHEMY_ID;


const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
  alchemyProvider({ alchemyId , priority: 1}),
  publicProvider({ priority: 2 })
]);



const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains })
  ],
  provider,
  webSocketProvider,
});



function App() {
  return (
    <WagmiConfig client={client}>
      <Body />
    </WagmiConfig>

  );
}

export default App;
